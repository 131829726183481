// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/Header.css */
.header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #e6e0cf;
    border-bottom: 1px solid #e6e0cf;
}

.logo {
    width: 200px; /* Adjust as needed */
    height: 200px; /* Adjust as needed */
    margin-right: 20px; /* Space between logo and text */
}

.title {
    font-size: 24px;
    line-height: 1; /* Adjust line height as needed */
}

.top-line {
    color: #b19963;
    display: block;
}

.line {
    color: #173150;
    display: block;
}  
`, "",{"version":3,"sources":["webpack://./src/assets/css/Header.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,yBAAyB;IACzB,gCAAgC;AACpC;;AAEA;IACI,YAAY,EAAE,qBAAqB;IACnC,aAAa,EAAE,qBAAqB;IACpC,kBAAkB,EAAE,gCAAgC;AACxD;;AAEA;IACI,eAAe;IACf,cAAc,EAAE,iCAAiC;AACrD;;AAEA;IACI,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,cAAc;AAClB","sourcesContent":["/* src/Header.css */\n.header {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 10px;\n    background-color: #e6e0cf;\n    border-bottom: 1px solid #e6e0cf;\n}\n\n.logo {\n    width: 200px; /* Adjust as needed */\n    height: 200px; /* Adjust as needed */\n    margin-right: 20px; /* Space between logo and text */\n}\n\n.title {\n    font-size: 24px;\n    line-height: 1; /* Adjust line height as needed */\n}\n\n.top-line {\n    color: #b19963;\n    display: block;\n}\n\n.line {\n    color: #173150;\n    display: block;\n}  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
