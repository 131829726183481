import React from 'react';
import '../css/Divider.css';

const Divider = () => {
  return (
    <div className="divider"></div>
  );
};

export default Divider;
