// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blue-div {
    background-color: #173150;
    padding: 20px;
}

.paragraph-text {
    color: #fff;
}
/* Modal.css or Global.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it is above other content */
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    z-index: 1001; /* Ensure it is above the overlay */
  }
  
  .blur-background {
    filter: blur(5px);
    transition: filter 0.3s ease;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/assets/css/Global.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,WAAW;AACf;AACA,4BAA4B;AAC5B;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B,EAAE,gCAAgC;IAChE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,qCAAqC;EACtD;;EAEA;IACE,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,aAAa,EAAE,mCAAmC;EACpD;;EAEA;IACE,iBAAiB;IACjB,4BAA4B;EAC9B","sourcesContent":[".blue-div {\n    background-color: #173150;\n    padding: 20px;\n}\n\n.paragraph-text {\n    color: #fff;\n}\n/* Modal.css or Global.css */\n.modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000; /* Ensure it is above other content */\n  }\n  \n  .modal-content {\n    background: white;\n    padding: 20px;\n    border-radius: 8px;\n    z-index: 1001; /* Ensure it is above the overlay */\n  }\n  \n  .blur-background {\n    filter: blur(5px);\n    transition: filter 0.3s ease;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
