// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider {
    width: 100%;
    height: 1px;
    background-color: #b19963; /* Color of the divider */
    margin: 10px 0; /* Optional: Adjust margin as needed */
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/css/Divider.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,yBAAyB,EAAE,yBAAyB;IACpD,cAAc,EAAE,sCAAsC;EACxD","sourcesContent":[".divider {\n    width: 100%;\n    height: 1px;\n    background-color: #b19963; /* Color of the divider */\n    margin: 10px 0; /* Optional: Adjust margin as needed */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
