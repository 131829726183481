import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import Divider from '../../assets/utilities/Divider';
import VerticalStepperComponent from './VerticalStepperComponent';
import Modal from './Modal';
import '../../assets/css/Global.css';
import '../../assets/css/VerticalStepperComponent.css';

const Welcome = () => {
  const { passId } = useParams(); // Use useParams to get passId from URL
  const [memberDetails, setMemberDetails] = useState(null);
  const [metadata, setMetadata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Initially set to false to show modal after loading
  const [modalContent, setModalContent] = useState({ type: 'disclaimer' });
  const [isWebAuthnSupported, setIsWebAuthnSupported] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [signedPdfUrl, setSignedPdfUrl] = useState('');
  const [entityId, setEntityId] = useState('');
  const [firstName, setFirstName] = useState(''); // New state for first name

  const enableWebAuthn = process.env.REACT_APP_ENABLE_WEBAUTHN === 'false';

  useEffect(() => {
    if (!enableWebAuthn) {
      setIsAuthenticated(true);
      return;
    }

    // Check if WebAuthn is supported
    if (!window.PublicKeyCredential) {
      setIsWebAuthnSupported(false);
      return;
    }

    const fetchChallenge = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_AUTH_CHALLENGE_URL);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
          throw new TypeError("Received non-JSON response");
        }
        const data = await response.json();
        if (!data.challenge) {
          throw new Error('Challenge data is missing');
        }
        return data.challenge;
      } catch (err) {
        console.error('Error fetching WebAuthn challenge:', err);
        setError(new Error('Error fetching WebAuthn challenge.'));
        setModalContent({ type: 'info', message: 'Error fetching WebAuthn challenge.' });
        setIsModalOpen(true);
        return null;
      }
    };

    const authenticate = async (challenge) => {
      try {
        const publicKey = {
          challenge: Uint8Array.from(atob(challenge.replace(/_/g, '/').replace(/-/g, '+')), c => c.charCodeAt(0)),
          rpId: window.location.hostname,
          userVerification: 'preferred',
        };

        const credential = await navigator.credentials.get({ publicKey });
        const clientDataJSON = credential.response.clientDataJSON;
        const authenticatorData = credential.response.authenticatorData;
        const signature = credential.response.signature;
        const rawId = credential.rawId;

        const credentialData = {
          id: credential.id,
          rawId: Array.from(new Uint8Array(rawId)),
          type: credential.type,
          response: {
            clientDataJSON: Array.from(new Uint8Array(clientDataJSON)),
            authenticatorData: Array.from(new Uint8Array(authenticatorData)),
            signature: Array.from(new Uint8Array(signature)),
          },
        };

        const verifyResponse = await fetch(process.env.REACT_APP_AUTH_VERIFY_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ challenge, credential: credentialData, passId }),
        });

        if (verifyResponse.ok) {
          const result = await verifyResponse.json();
          if (result.verified) {
            setIsAuthenticated(true);
            setIsModalOpen(false);
          } else {
            throw new Error('Authentication failed.');
          }
        } else {
          throw new Error('Authentication failed.');
        }
      } catch (err) {
        console.error('WebAuthn error:', err);
        setError(new Error('WebAuthn error.'));
        setModalContent({ type: 'info', message: 'WebAuthn error.' });
        setIsModalOpen(true);
      }
    };

    const initAuthentication = async () => {
      const challenge = await fetchChallenge();
      if (challenge) {
        authenticate(challenge);
      }
    };

    initAuthentication();
  }, [enableWebAuthn, passId]);

  useEffect(() => {
    if (!passId) {
      setLoading(false);
      setError(new Error("You are not authorized to view this page."));
      setModalContent({ type: 'info', message: 'You are not authorized to view this page.' });
      setIsModalOpen(true);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_MEMBER_DATA_URL}/${passId}`);
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
          throw new TypeError("Received non-JSON response");
        }
        const text = await response.text();
        const data = text ? JSON.parse(text) : {};
        console.log('Data:', data);
        // Handle the new response structure
        setMemberDetails(data.memberDetails);
        setFirstName(data.memberDetails.first_name);
        setMetadata(data.metadata);
        setEntityId(data.memberDetails.entity_id); // Set the entity_id from the payload

        // Fetch the signed URL for the benefitsPdf
        const benefitsPdfMetadata = data.metadata.find(item => item.key === 'benefitsPdf');
        if (benefitsPdfMetadata) {
          const signedUrlResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/signed-url?key=${benefitsPdfMetadata.value}`);
          if (!signedUrlResponse.ok) {
            throw new Error('Error fetching signed URL');
          }
          const signedUrlData = await signedUrlResponse.json();
          setSignedPdfUrl(signedUrlData.url);
        }
        
        setIsModalOpen(true); // Open the modal after data is fetched
      } catch (err) {
        setError(err);
        setModalContent({ type: 'info', message: err.message });
        setIsModalOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [passId]);

  const handleAcknowledge = async (acknowledgmentData) => {
    try {
      console.log('Sending Option 1 selection:', acknowledgmentData); // Log the data being sent
  
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/metadata/v1/put`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(acknowledgmentData), 
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      Cookies.set('disclaimerAcknowledged', 'true', { expires: 365 }); // 1 year expiration
      console.log('User acknowledged the disclaimer');
      setIsModalOpen(false); // Close the modal after acknowledgment
    } catch (error) {
      console.error('Error acknowledging disclaimer:', error);
    }
  };

  const handleCompleteStep2 = async (selection) => {
    console.log('Step2 selection:', selection);
    try {
      const selectionData = {
        entityId: entityId,
        key: `step2_selection`,
        value: JSON.stringify(selection),
      };

      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/metadata/v1/put`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(selectionData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      await response.json();
      setModalContent({ type: 'thankYou' });
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error submitting step 2 selection:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div className="welcome-page">
        <div className="blue-div">
          <p className="paragraph-text">
            {error.message}
          </p>
        </div>
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          content={modalContent}
          entityId={entityId} // Pass the entityId to Modal
          firstName={firstName}
          onAcknowledge={handleAcknowledge} // Pass the handleAcknowledge function
        />
      </div>
    );
  }

  return (
    <div> {/* Apply blur class conditionally */}
      <div className="blue-div">
        <Divider />
        <VerticalStepperComponent
          memberDetails={memberDetails}
          metadata={metadata}
          signedPdfUrl={signedPdfUrl}
          onCompleteStep2={handleCompleteStep2}
          onAcknowledge={handleAcknowledge} // Pass the handleAcknowledge function
          isAuthenticated={isAuthenticated}
        />
      </div>
      {isModalOpen && (
         <div className="modal-overlay">
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          content={modalContent}
          entityId={entityId}
          firstName={firstName}
          onAcknowledge={handleAcknowledge} // Pass the handleAcknowledge function
        />
        </div>
      )}
    </div>
  );
};

export default Welcome;
