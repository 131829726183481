import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footerdata from './data/footerdata.json';

import Home from './components/Home';
import Header from './components/components-global/Header';
import Footer from './components/components-global/Footer';
import ScrollToTop from './components/components-global/ScrollToTop';
import Welcome from './components/components-home/Welcome';
import ActivationForm from './components/components-home/ActivationForm'; // Import the new component

const container = document.getElementById('root');
const root = createRoot(container);

const Root = () => (
  <Router>
    <ScrollToTop />
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Harlan/:passId" element={<Welcome />} />
        <Route path="/form" element={<ActivationForm />} /> {/* Add the new route */}
      </Routes>
      <Footer data={Footerdata} />
    </div>
  </Router>
);

root.render(<Root />);
