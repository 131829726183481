import React, { useState } from 'react';
import '../../assets/css/VerticalStepperComponent.css';

const Step2Content = ({ handleNext, handlePrevious, onComplete }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [fsaAmount, setFsaAmount] = useState(0);
  const [dependentFsaAmount, setDependentFsaAmount] = useState(0);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSliderChange = (event, type) => {
    const value = parseInt(event.target.value, 10);
    if (type === 'fsa') {
      setFsaAmount(value);
    } else if (type === 'dependentFsa') {
      setDependentFsaAmount(value);
    }
  };

  const handleSubmit = () => {
    if (selectedOption) {
      onComplete({
        action: 'Selected Option',
        option: selectedOption,
        fsaAmount,
        dependentFsaAmount,
        timestamp: new Date().toISOString()
      });
    } else {
      alert('Please select an option');
    }
  };

  const handleScheduleMeeting = () => {
    window.location.href = 'https://calendly.com/harlan-foods';
  };

  const handleGoToSmartBen = () => {
    window.location.href = 'https://harlanbakeries.smartben.net';
  };

  return (
    <div>
      <p>Make Your 10/1/24 Benefits Elections. Select from one of the three choices below.</p>
      <div className="option-container">
        <label>
          <input
            type="radio"
            name="benefitOption"
            value="option1"
            checked={selectedOption === 'option1'}
            onChange={handleOptionChange}
          />
          <strong>Option 1:</strong> No changes to my current benefit elections, and I do not wish to contribute to a Flexible Spending Account (FSA).
        </label>
      </div>
      <div className="option-container">
        <label>
          <input
            type="radio"
            name="benefitOption"
            value="option2"
            checked={selectedOption === 'option2'}
            onChange={handleOptionChange}
          />
          <strong>Option 2:</strong> No changes to my current benefit elections, and I would like to contribute to a Healthcare FSA and/or Dependent Care FSA.
        </label>
        {selectedOption === 'option2' && (
          <div className="slider-table">
            <div className="slider-row header-row">
              <div className="slider-cell">FSA Type</div>
              <div className="slider-cell">Min</div>
              <div className="slider-cell">Slider</div>
              <div className="slider-cell">Max</div>
              <div className="slider-cell">Annual Amount</div>
              <div className="slider-cell">Bi-Weekly Amount</div>
              <div className="slider-cell">Weekly Amount</div>
            </div>
            <div className="slider-row">
              <div className="slider-cell">Healthcare FSA</div>
              <div className="slider-cell">$0</div>
              <div className="slider-cell">
                <input
                  type="range"
                  min="0"
                  max="3200"
                  value={fsaAmount}
                  onChange={(event) => handleSliderChange(event, 'fsa')}
                />
              </div>
              <div className="slider-cell">$3200</div>
              <div className="slider-cell">${fsaAmount}</div>
              <div className="slider-cell">${(fsaAmount / 26).toFixed(2)}</div>
              <div className="slider-cell">${(fsaAmount / 52).toFixed(2)}</div>
            </div>
            <div className="slider-row">
              <div className="slider-cell">Dependent Care FSA</div>
              <div className="slider-cell">$0</div>
              <div className="slider-cell">
                <input
                  type="range"
                  min="0"
                  max="5000"
                  value={dependentFsaAmount}
                  onChange={(event) => handleSliderChange(event, 'dependentFsa')}
                />
              </div>
              <div className="slider-cell">$5000</div>
              <div className="slider-cell">${dependentFsaAmount}</div>
              <div className="slider-cell">${(dependentFsaAmount / 26).toFixed(2)}</div>
              <div className="slider-cell">${(dependentFsaAmount / 52).toFixed(2)}</div>
            </div>
          </div>
        )}
      </div>
      <div className="option-container">
        <label>
          <input
            type="radio"
            name="benefitOption"
            value="option3"
            checked={selectedOption === 'option3'}
            onChange={handleOptionChange}
          />
          <strong>Option 3:</strong> Adjust my Benefit elections for next plan year. If you are a new employee and have not completed enrollment yet, select this option.
        </label>
      </div>
      {selectedOption === 'option3' ? (
        <div className="button-group">
          <button className="option-button" onClick={handleScheduleMeeting}>
            Schedule a Meeting
          </button>
          <button className="option-button" onClick={handleGoToSmartBen}>
            Go to SmartBen
          </button>
        </div>
      ) : (
        selectedOption && (
          <div className="button-group">
            <button className="option-button" onClick={handleSubmit}>
              Acknowledge Selection
            </button>
          </div>
        )
      )}
    </div>
  );
};

export default Step2Content;
