import React, { useState } from 'react';
import '../../assets/css/Modal.css';

const Modal = ({ isOpen, onClose, content, entityId, firstName, onAcknowledge }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleAcknowledge = () => {
    if (isChecked) {
      if (!entityId) {
        console.error('Entity ID is missing');
        return;
      }
      
      const acknowledgmentData = {
        entityId: entityId,
        key: 'disclamerAcknowledged',
        value: 'true'
      };

      onAcknowledge(acknowledgmentData); // Pass the data back to the parent component
      onClose(); // Close the modal after acknowledgment
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleRedirect = () => {
    window.location.href = 'https://harlanfoods.com/';
  };

  const handleContactUs = () => {
    console.log('Contact Us button clicked');
  };

  const handleSendEmail = () => {
    window.location.href = 'mailto:will@connectxdigital.com';
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content" id="disclaimerModal">
        {content.type === 'disclaimer' && (
          <>
            <h2>Welcome, {firstName}!</h2>
            <p>
              By clicking "Proceed", you confirm that you are the individual identified and that the information displayed is confidential. If you do not agree, click "Cancel".
            </p>
            <div className="checkbox-container">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label>I acknowledge</label>
            </div>
            <div className="button-group">
              <button onClick={handleAcknowledge} className="option-button" disabled={!isChecked}>
                Proceed
              </button>
              <button onClick={handleCancel} className="cancel-button">
                Contact HR
              </button>
            </div>
          </>
        )}
        {content.type === 'thankYou' && (
          <>
            <h2>Thank You! 🎉</h2>
            <p>Thank you for completing your benefit selection.</p>
            <button onClick={handleRedirect} className="option-button">
              OK
            </button>
          </>
        )}
        {content.type === 'info' && (
          <>
            <h2>Information</h2>
            <p>{content.message}</p>
            {content.message === 'Error fetching WebAuthn challenge.' && (
              <>
                <p>If you think you have reached this by mistake, click here to send a message.</p>
                <button onClick={handleContactUs} className="option-button">Contact Us</button>
              </>
            )}
            <button onClick={onClose} className="option-button">Close</button>
          </>
        )}
        {content.isContact && (
          <>
            <h2>Contact Us</h2>
            <p>Send an email to will@connectxdigital.com</p>
            <button onClick={handleSendEmail} className="option-button">Send Email</button>
            <button onClick={onClose} className="option-button">Close</button>
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
