import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import { HashLink as HLink } from 'react-router-hash-link';
import '../../assets/css/Header.css';

class Header extends Component {

   render() {
      return (
      <header className="header">
         <img src={'https://cxd-test-images.s3.us-east-2.amazonaws.com/Harlan/Harlan_logo.png' } alt="HarlanLogo" className="logo" />
         <h1 className="title">
            <span className="top-line">Welcome to the</span>
            <span className="line">Open Enrollment</span>
            <span className="line">Benefits Portal</span>
         </h1>
      </header>
      );
   }
}

export default Header;
