import React from 'react';
import Welcome from './components-home/Welcome';
// import CustomButton from './components-home/DEV_getToken';
import Divider from '../assets/utilities/Divider';
import '../assets/css/Global.css';
const Home = () => {

    return (
      <div>
        <Welcome />
        <Divider />
        {/* <CustomButton /> */}
      </div>


    );
 
}
export default Home;
